import LatestNews1 from "../../assets/LatestNews1.svg";
import LatestNews2 from "../../assets/LatestNews2.svg";
function LatestNews() {
  return (
    <div className="container mt-7 md:mt-14">
      <h1 className="text-xl md:text-3xl font-bold mb-4">اخر الاخبار </h1>
      <div className="grid grid-cols-1 gap-5 md:gap-10">
        <div
          className="h-[200px] md:h-[400px] rounded-tl-3xl relative"
          style={{
            backgroundImage: `url(${LatestNews1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="bg-[#294BA1E0] text-white text-sm md:text-3xl tracking-wider absolute left-0 bottom-5 w-full p-3 md:p-5 ">
            السعودية تلغي رسوم تراخيص الفنادق في مسعى لدعم السياحة
          </h1>
        </div>
        <div
          className="h-[200px] md:h-[400px] rounded-tl-3xl relative"
          style={{
            backgroundImage: `url(${LatestNews2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="bg-[#294BA1E0] text-white text-sm md:text-3xl tracking-wider absolute left-0 bottom-5 w-full p-3 md:p-5 ">
            السعودية تلغي رسوم تراخيص الفنادق في مسعى لدعم السياحة
          </h1>
        </div>
      </div>
    </div>
  );
}

export default LatestNews;
