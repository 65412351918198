import React from "react";
import Carousel from "../../Sections/Carousel/Carousel";
import Download from "../../Sections/Download/Download";
import Hero from "../../Sections/Hero/Hero";
import SliderHero from "../../Sections/SliderHero/SliderHero";
import Hejozati from "../../Sections/Hejozati/Hejozati";
import LatestNews from "../../Sections/LatestNews/LatestNews";
import PlatformNews from "../../Sections/PlatformNews/PlatformNews";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";
import Sponsored from "../../Sections/Sponsored/Sponsored";

function Home() {
  return (
    <>
      <Carousel />
      <Download />
      <Hero />
      <SliderHero />
      <Hejozati />
      <LatestNews />
      <PlatformNews />
      <Sponsored />
      <Whatsapp />
    </>
  );
}

export default Home;
