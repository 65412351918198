import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../Components/RootLayout/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "../Page/Home/Home";
import Login from "../Page/Login/Login";
import Registration from "../Page/Registration/Registration";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "Login", element: <Login /> },
      { path: "Registration", element: <Registration /> },
    ],
  },
]);
